<template>
  <div>
    <el-dialog v-model="visible" @open="onOpen" @close="onClose" title="添加">
      <el-form
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="mini"
        label-width="px"
        label-position="left"
      >
        <el-form-item label-width="80px" label="微信群名称" prop="roomName">
          <el-input
            v-model="formData.roomName"
            placeholder="请输入微信群名称"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="80px" label="公司名称" prop="companyName">
          <el-input
            v-model="formData.companyName"
            placeholder="请输入公司名称"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="80px" label="mn号" prop="mn">
          <el-input
            v-model="formData.mn"
            placeholder="请输入mn号"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="80px" label="数据类型" prop="dataType">
          <el-input
            v-model="formData.dataType"
            placeholder="请输入数据类型，例如[实时数据，分钟数据，小时数据]"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="80px" label="超标发送间隔(单位:分钟)" prop="interval">
          <el-input
            v-model="formData.interval"
            placeholder="请输入超标发送间隔(单位:分钟)"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="80px" label="呆滞发送间隔(单位:分钟)" prop="slackIntervalTime">
          <el-input
              v-model="formData.slackIntervalTime"
              placeholder="请输入呆滞发送间隔(单位:分钟)"
              clearable
              prefix-icon="el-icon-user"
              :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <div>
          <slot name="footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" @click="handleConfirm">确定</el-button>
          </slot>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {addWechatBot} from "../../../../api/wechat-bot";

export default {
  name: "WechatBotAdd",
  components: {},
  props: { dialogVisible: Boolean },
  data() {
    return {
      visible: false,
      formData: {
        id: undefined,
        roomName: "",
        companyName: "",
        mn: "",
        dataType: "",
        interval: "",
        slackIntervalTime: undefined,
        lastSendTime: undefined,
        slackLastSendTime: undefined,
        enable: undefined
      },
      rules: {
        roomName: [
          {
            required: true,
            message: "请输入微信群名称",
            trigger: "blur"
          }
        ],
        companyName: [
          {
            required: true,
            message: "请输入公司名称",
            trigger: "blur"
          }
        ],
        mn: [
          {
            required: true,
            message: "请输入mn号",
            trigger: "blur"
          }
        ],
        dataType: [
          {
            required: true,
            message: "请输入数据类型，例如[实时数据，分钟数据，小时数据]",
            trigger: "blur"
          }
        ],
        interval: [
          {
            required: true,
            message: "请输入发送间隔",
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {},
  watch: {
    dialogVisible: {
      handler(value) {
        this.visible = value;
      }
    }
  },
  created() {},
  mounted() {
    this.visible = this.dialogVisible;
  },
  methods: {
    onOpen() {},
    onClose() {
      this.$refs["elForm"].resetFields();
      this.$emit("updateVisible");
    },
    close() {
      this.$emit("updateVisible");
    },
    handleConfirm() {
      this.$refs["elForm"].validate(valid => {
        if (!valid) {
          return;
        }
        // 请求参数
        const requestBody = {
          id: undefined,
          roomName: this.formData.roomName,
          companyName: this.formData.companyName,
          mn: this.formData.mn,
          dataType: this.formData.dataType,
          interval: this.formData.interval,
          lastSendTime: undefined,
          enable: undefined
        };
        // 发送请求
        addWechatBot(requestBody).then(() => {
          // 重新请求table
          this.$parent.getAllWechatBot();
        });
        // 关闭窗口
        this.close();
      });
    }
  }
};
</script>
<style></style>

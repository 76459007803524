<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-chat-dot-round"></i> 信息管理
        </el-breadcrumb-item>
        <el-breadcrumb-item>微信机器人</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!--    <el-card shadow="always">-->
    <!--      <el-row :gutter="5">-->
    <!--        <el-col :span="5">-->
    <!--          <el-input clearable size="mini" v-model="mnInput" placeholder="mn">-->
    <!--            <template #prepend-->
    <!--              ><i class="el-icon-search"></i>输入搜索:</template-->
    <!--            >-->
    <!--          </el-input>-->
    <!--        </el-col>-->
    <!--        <div style="float: left">-->
    <!--          <el-button-->
    <!--            @click="handleClear"-->
    <!--            plain-->
    <!--            icon="el-icon-delete"-->
    <!--            size="mini"-->
    <!--          >-->
    <!--            清空</el-button-->
    <!--          >-->
    <!--          <el-button-->
    <!--            @click="handleSearch"-->
    <!--            plain-->
    <!--            type="primary"-->
    <!--            icon="el-icon-search"-->
    <!--            size="mini"-->
    <!--          >-->
    <!--            查询-->
    <!--          </el-button>-->
    <!--        </div>-->
    <!--      </el-row>-->
    <!--    </el-card>-->
    <el-card shadow="always">
      <el-row>
        <el-button
          @click="handleAddWechatBot"
          type="primary"
          icon="el-icon-edit"
          size="mini"
        >
          添加
        </el-button>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" :index="1"> </el-table-column>
        <el-table-column prop="roomName" label="微信群名称"> </el-table-column>
        <el-table-column prop="companyName" label="公司名称"> </el-table-column>
        <el-table-column prop="mn" label="mn号"> </el-table-column>
        <el-table-column prop="dataType" label="数据类型"> </el-table-column>
        <el-table-column prop="interval" label="超标发送间隔(单位:分钟)"> </el-table-column>
        <el-table-column prop="slackIntervalTime" label="呆滞发送间隔(单位:分钟)"> </el-table-column>
        <el-table-column label="操作" width="100px">
          <template #default="scope">
            <el-button
              size="mini"
              type="text"
              @click="handleEdit(scope.$index, scope.row)"
            >
              编辑
            </el-button>
            <el-button
              size="mini"
              type="text"
              @click="handleDelete(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <wechat-bot-add
      :dialog-visible="dialogVisibleWechatBotAdd"
      @updateVisible="closeWechatBotAdd"
    ></wechat-bot-add>
    <wechat-bot-edit
      :dialog-visible="dialogVisibleWechatBotEdit"
      @updateVisible="closeWechatBotEdit"
      :row="editWechatBot"
    ></wechat-bot-edit>
  </div>
</template>

<script>
import WechatBotEdit from "./dialog/WechatBotEdit.vue";
import WechatBotAdd from "./dialog/WechatBotAdd.vue";
import { deleteWechatBot, getAllWechatBot } from "../../../api/wechat-bot";

export default {
  name: "WechatBot",
  components: { WechatBotAdd, WechatBotEdit },
  data() {
    return {
      mnInput: "",
      editWechatBot: {},
      tableData: [],
      dialogVisibleWechatBotAdd: false,
      dialogVisibleWechatBotEdit: false
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleClear() {
      this.mnInput = "";
    },
    handleSearch() {
      console.log(this.mnInput);
      this.getList();
    },
    handleAddWechatBot() {
      this.dialogVisibleWechatBotAdd = true;
    },
    closeWechatBotAdd() {
      this.dialogVisibleWechatBotAdd = false;
    },
    handleEdit(index, row) {
      this.dialogVisibleWechatBotEdit = true;
      this.editWechatBot = JSON.parse(JSON.stringify(row));
    },
    closeWechatBotEdit() {
      this.dialogVisibleWechatBotEdit = false;
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.editWechatBot = row;
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          // 请求参数
          const params = {
            roomName: row.roomName,
            mn: row.mn,
            dataType: row.dataType
          };
          // 发送请求
          deleteWechatBot(params).then(() => {
            this.getList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    getList() {

      getAllWechatBot().then(result => {
        console.log(JSON.stringify(result));
        this.tableData = result.data;
      });
    }
  }
};
</script>

<style scoped></style>
